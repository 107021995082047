<template>
    <div class="c-cookie-panel">

        <div class="c-cookie-panel__content">
            <div class="c-cookie-panel__header">
                {{ trans('common.cookies.manage') }}
            </div>
            <div class="c-cookie-panel__description">
                {{ trans('common.cookies.panel.summary') }}
                <span class="c-cookie-panel__link">
                    <a
                        :href="`${baseUrl}/cookie-policy`"
                        class="c-cookie-panel__link"
                        target="_blank"
                    >
                        {{ trans('common.cookies.cookie') }}.
                    </a>
                </span>
            </div>

            <div
                class="c-cookie-panel__btn--primary u-hover--grow u-bot-margin--35"
                :class="colorClass + '__bg'"
                @click="$emit('acceptCookies')"
            >
                {{ trans('common.cookies.accept-all') }}
            </div>

            <div class="c-cookie-panel__section">
                <div class="c-cookie-panel__section--header">
                    <div class="c-cookie-panel__section--title">
                        {{ trans('common.cookies.panel.essential.title') }}
                    </div>
                    <div class="c-cookie-panel__active">
                        {{ trans('common.cookies.panel.active') }}
                    </div>
                </div>
                <div class="c-cookie-panel__section--description">
                    {{ trans('common.cookies.panel.essential.description') }}
                </div>
            </div>

            <div class="c-cookie-panel__section">
                <div class="c-cookie-panel__section--header">
                    <div class="c-cookie-panel__section--title">
                        {{ trans('common.cookies.panel.functional.title') }}
                    </div>
                    <div class="c-cookie-panel__toggle">
                        <toggle-button
                            class="changed-font"
                            :color="{ checked: '#333', unchecked: '#bbb' }"
                            :labels="{ checked: trans('common.on'), unchecked: trans('common.off') }"
                            :width="70"
                            :height="30"
                            :modelValue="preferences.functional"
                            @update:modelValue="preferences.functional = $event"
                        >
                        </toggle-button>
                    </div>
                </div>
                <div class="c-cookie-panel__section--description">
                    {{ trans('common.cookies.panel.functional.description') }}
                </div>
            </div>

            <div class="c-cookie-panel__section">
                <div class="c-cookie-panel__section--header">
                    <div class="c-cookie-panel__section--title">
                        {{ trans('common.cookies.panel.analytics.title') }}
                    </div>
                    <div class="c-cookie-panel__toggle">
                        <toggle-button
                            class="changed-font"
                            :color="{ checked: '#333', unchecked: '#bbb' }"
                            :labels="{ checked: trans('common.on'), unchecked: trans('common.off') }"
                            :width="70"
                            :height="30"
                            :modelValue="preferences.analytics"
                            @update:modelValue="preferences.analytics = $event"
                        >
                        </toggle-button>
                    </div>
                </div>
                <div class="c-cookie-panel__section--description">
                    {{ trans('common.cookies.panel.analytics.description') }}
                </div>
            </div>

            <div
                class="c-cookie-panel__btn--primary u-hover--grow"
                :class="colorClass + '__bg'"
                @click="savePreferences"
            >
                {{ trans('common.cookies.save') }}
            </div>
        </div>
    </div>
</template>

<script>

import { setPreferences, getPreferences } from '@js/core/cookies/cookiePreferences.js';

const DEFAULT = {
    analytics: false,
    functional: false,
};
export default {
    name: 'CookiePanel',
    components: {
    },
    mixins: [
    ],
    emits: ['acceptCookies', 'closePanel'],
    props: {
        colorClass: {
            type: String,
            default: 'u-primary-color',
        },
    },
    data() {
        const preferences = getPreferences() || DEFAULT;
        return {
            preferences: preferences,
            baseUrl: 'https://www.ezekia.io',
        };
    },
    methods: {
        savePreferences() {
            setPreferences(this.preferences);
            this.$emit('closePanel');
        },
    },
};
</script>

<style lang="scss" scoped>

@import "@sass/variables";
@import "@sass/mixins";

.c-cookie-panel {
    align-items: center;
    background-color: white;
    bottom: 0;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: z("cookie-panel");

    &__content {
        overflow-y: scroll;
        padding: 25px 12px;
    }

    &__header {
        font-size: 22px;
        margin-bottom: 20px;
        text-align: center;
    }

    &__description {
        margin-bottom: 25px;
    }

    &__link {
        border-bottom: 1px solid #000;
        cursor: pointer;

        &:hover {
            color: #000;
        }
    }

    &__section {
        margin-bottom: 30px;

        &--header {
            align-items: baseline;
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        &--title {
            font-size: 16px;
            font-weight: 600;
        }

        &--description {
            color: #666;
        }
    }

    &__active {
        color: #333;
        margin-bottom: 10px;
    }

    &__btn--primary {
        color: #fff;
        display: inline-flex;
        font-size: 15px;
        padding: 6px 18px;
    }

    @media (width >= 380px) {
        width: 380px;

        &__content {
            padding: 25px 20px 35px;
        }
    }
}
</style>